
                @import 'src/stylesheets/variables.scss';
                @import 'src/stylesheets/mixins.scss';
            

.loading-toast {
    display: flex;

    .info {
        text-align: bottom;
        line-height: 200%;
    }

    .spinner {
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background: inherit;

        .arc {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 3px solid rgba(0,0,0,0);
            border-top: 3px solid white;
            border-left: 3px solid white;
            box-sizing: border-box;
            border-radius: 50%;

            animation: notification-spinner 1s linear infinite .1s;
        }
        .mask {
            position: absolute;
            left: -10%;
            top: -10%;
            width: 120%;
            height: 60%;
            background: inherit;
            transform-origin: bottom center;

            animation: notification-spinner 1s ease infinite;
        }
        @keyframes notification-spinner {
            0% {
                transform: rotate(90deg);
            }
            100% {
                transform: rotate(450deg);
            }
        }
    }
}
