
                @import 'src/stylesheets/variables.scss';
                @import 'src/stylesheets/mixins.scss';
            

.pending-tasks-container {
    max-width: 80%;
    min-width: 500px;
    position: relative;
    top: 0px;
    transition: margin .15s ease;
    
    span.bold {
        font-weight: bold;
    }
    .pending-tasks-card {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .row {
        display: flex;
    }
    .required {
        color: red;
    }
    .button-container {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 20px;
    }
}
