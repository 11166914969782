<template lang="pug">
Teleport(to="body"): .modal-scrim(:class="[...scrimClasses]" @click="$emit('close')"): .modal-contents(:class="[...contentClasses]" @click.stop): slot
</template>
<script setup lang="ts">
interface Props {
    scrimClasses?: string[]
    contentClasses?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  scrimClasses: () => [],
  contentClasses: () => []
})
defineEmits<{
    (e: 'close'): void
}>();
</script>
<style>
.modal-scrim {
    height: 100%;
    width: 100%;
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(121, 121, 121, .7);
    overflow: auto;
    padding-left: 3.9rem;

    .modal-contents {
        box-sizing: border-box;
        max-height: 100%;
        max-width: 100%;
    }
}

.g-sidenav-show .modal-scrim {
    padding-left: 16rem;
}
</style>