.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 0.2s ease-in-out;
}


.fade-up-enter-to {
  transform: translateY(0%);
  opacity: 1

}


.fade-up-enter-from {
  transform: translateY(-15%) ;
  opacity: 0
}


.fade-up-leave-to {
  transform: translateY(15%) scale(.95);
  opacity: 0

}


.fade-up-leave-from {
  opacity: 1;
}