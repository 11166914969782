button.close{
    background-image: url(../resources/img/icons/common/close-times.svg);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    &, .close, .path, path{
        fill: var(--gray-800);
        opacity: 1;
    }
}

.ws-nowrap {
    white-space: nowrap;
}