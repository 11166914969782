.primary-input{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
    label{
        margin-left: 6px;
    }
    input{
        padding: 12px 5px 12px 12px;
        border-radius: 6px;
        border: 1px solid #E8E8E8;
    }
}